// Chakra imports
import { Box, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

// Assets
import { MdEdit } from 'react-icons/md';
import { SmallCloseIcon } from '@chakra-ui/icons'
import {useUser} from "@clerk/clerk-react";
import { Buffer } from 'buffer';

export default function DocsBar(props: {
	filename: string;
	filetype: string;
	path: string;
	setGotDocuments: any;
	setShowSpinner: any;
	// phone_number: string;
	// ranking: string;
	// link: string;
	// image: string;
	[x: string]: any;
}) {
	const { filename, filetype, path, setGotDocuments, setShowSpinner, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const brandColor = useColorModeValue('brand.500', 'white');
	const bg = useColorModeValue('white', 'navy.700');
	const { user } = useUser();
	const user_id = user.id;

	const removeFileFromServer = () => {
		const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA}?user_id=${user_id}&file_name=${filename}&file_type=${filetype}&path=${path}&fields=file`
		console.log("calling " + url);
		setShowSpinner(true);
		fetch(url, {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			},
			body: JSON.stringify({
				user_id: user_id,
				file_name: filename,
				file_type: filetype,
				path: path,
				fields: 'file'
			})
		}).then((data) => data.json())
			.then((resp) => {
				setGotDocuments(false);
			}).catch((error) => {
			console.log("got error when calling " + url);
			console.log(error);
			setGotDocuments(false);
		})
	}

	const viewFile = () => {
		const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&file_name=${filename}&file_type=${filetype}&path=${path}`
		setShowSpinner(true)
		fetch(url, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			}
		}).then((data) => data.blob())
			.then((response) => {
				const file = new Blob([response], {
					type: 'application/pdf',
				});
				const fileURL = URL.createObjectURL(file);

				window.open(fileURL);

				// const file = new Blob([Buffer.from(response)], { type: "application/pdf" });
				// //Build a URL from the file
				// const fileURL = URL.createObjectURL(file);
				// //Open the URL on new Window
				// const pdfWindow = window.open();
				// pdfWindow.location.href = fileURL;
				setGotDocuments(false);
			}).catch((error) => {
				console.log("got error when calling " + url);
				console.log(error);
				setGotDocuments(false);
		})
	}

	return (
		<Card bg={bg} {...rest} p='14px'>
			<Flex align='center' direction={{ base: 'column', md: 'row' }}>
				{/*<Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' />*/}
				<Box mt={{ base: '10px', md: '0' }}>
					<Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
						{filename}
					</Text>
					<Text fontWeight='500' color={textColorSecondary} fontSize='sm' me='4px'>
						{filename} •{' '}
						<Link fontWeight='500' color={brandColor} onClick={() => viewFile()} fontSize='sm'>
							View File
						</Link>
					</Text>

				</Box>
				<Link onClick={() => removeFileFromServer()} variant='no-hover' me='16px' ms='auto' p='0px !important'>
					<SmallCloseIcon/>
				</Link>
			</Flex>
		</Card>
	);
}
