import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
// Chakra imports

import {
    ClerkProvider,
    SignedIn,
    SignedOut,
    RedirectToSignIn,
    SignIn,
    SignUp,
    UserButton,
} from "@clerk/clerk-react";
import React from "react";


export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
              <>
                  <SignedIn>
                      <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
                  </SignedIn>
                  <SignedOut>
                      {/*<SignIn />*/}
                      <AuthLayout />
                  </SignedOut>
              </>
          }
        />
        <Route
          path="rtl/*"
          element={
              <>
                  <SignedIn>
                      <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
                  </SignedIn>
                  <SignedOut>
                      {/*<SignIn />*/}
                      <AuthLayout />
                  </SignedOut>
              </>
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
