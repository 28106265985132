// Chakra imports
import { Box, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

// Assets
import { MdEdit } from 'react-icons/md';
import { SmallCloseIcon } from '@chakra-ui/icons'
import {useUser} from "@clerk/clerk-react";

export default function Numbers(props: {
	phone_number: any;
	setShowSpinner: any;
	setGotNumbers: any;
	// ranking: string;
	// link: string;
	// image: string;
	[x: string]: any;
}) {
	const { phone_number, setShowSpinner, setGotNumbers, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const brandColor = useColorModeValue('brand.500', 'white');
	const bg = useColorModeValue('white', 'navy.700');
	const { user } = useUser();
	const user_id = user.id;
	console.log("got phone number");
	console.log(phone_number);
	const removeNumberFromServer = () => {
		const url =  `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&phone_number=${phone_number}`
		setShowSpinner(true);
		fetch(url, {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			},
			body: JSON.stringify({
				user_id: user_id,
				phone_number: phone_number
			})
		}).then((data) => data.json())
			.then((resp) => {
				setGotNumbers(false);
			}).catch((error) => {
			console.log("got error when calling " + url);
			console.log(error);
			setGotNumbers(false);
		})
	}


	return (
		<Card bg={bg} {...rest} p='14px'>
			<Flex align='center' direction={{ base: 'column', md: 'row' }}>
				{/*<Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' />*/}
				<Box mt={{ base: '10px', md: '0' }}>
					<Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
						{phone_number}
					</Text>
				</Box>
				<Link onClick={() => removeNumberFromServer()} variant='no-hover' me='16px' ms='auto' p='0px !important'>
					<SmallCloseIcon/>
				</Link>
			</Flex>
		</Card>
	);
}
