// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';
// Assets
import Project1 from 'assets/img/users/Project1.png';
import Project2 from 'assets/img/users/Project2.png';
import Project3 from 'assets/img/users/Project3.png';
// Custom components
import Card from 'components/card/Card';
import { useEffect } from 'react';

import DocsBar from 'views/admin/main/profile/overview/components/DocsBar';

// @ts-ignore
export default function ConnectedDocuments({connected_documents, setGotDocuments, setShowSpinner}) {
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';

	return (
		<Card mb={{ base: '0px', '2xl': '20px' }}>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Connected Documents
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				The docs below are all the information our agent knows about your business. We use fine tuned large
				language models to parse this data, and then to answer customer queries.
			</Text>
			{connected_documents && connected_documents['files'] ? connected_documents['files'].map((item: { filename: string; filetype: string; path: string;  }, i: number) => (
				<DocsBar mb='20px' image={Project1} ranking={(i+1).toString()} link='#' filename={item.filename} filetype={item.filetype} path={item.path} setGotDocuments={setGotDocuments} setShowSpinner={setShowSpinner}/>
			)) : ''}
			{/*<Project mb='20px' image={Project1} ranking='1' link='#' title='Technology behind the Blockchain' />*/}
			{/*<Project mb='20px' image={Project2} ranking='2' link='#' title='Greatest way to a good Economy' />*/}
			{/*<Project image={Project3} ranking='3' link='#' title='Most essential tips for Burnout' />*/}
		</Card>
	);
}
