/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/
=========================================================
* Equinox Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Equinox (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Since this is a specific page, if you want to make it work like a real chat, we have some suggestions:
// 1. Add to the `Messages` components a `data` prop which will pass the messages fetched from a server.
// 2. Put all `ChatHeader` as Chakra UI `Tab` and each of them will open a panel, which will be your `Messages` component with its data.

// These are purely suggestions, although in Horizon v2.0 we will make an example with a simulated chat !

// Chakra imports
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	Flex,
	Grid,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	DrawerCloseButton,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
// Assets
import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar4 from 'assets/img/avatars/avatar4.png';
import avatar5 from 'assets/img/avatars/avatar5.png';
import avatar6 from 'assets/img/avatars/avatar6.png';
import avatar7 from 'assets/img/avatars/avatar7.png';
import avatar8 from 'assets/img/avatars/avatar8.png';
import avatar9 from 'assets/img/avatars/avatar9.png';
import { FiSearch } from 'react-icons/fi';
import { MdChevronLeft } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import {
	MdOutlineCardTravel,
	MdOutlineLightbulb,
	MdOutlineMoreVert,
	MdOutlinePerson,
	MdOutlineSettings
} from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import Messages from 'views/admin/main/others/messages/components/Messages';
import ChatHeader from 'components/chat/ChatHeader';
import React, {useEffect, useState} from "react";
import {useUser} from "@clerk/clerk-react";
import scrollToBottom, { Scrollbars } from 'react-custom-scrollbars-2';
// import Conversations from 'views/admin/main/others/messages/components/Conversations';

export default function Default() {
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputText = useColorModeValue('gray.700', 'gray.100');
	const blockBg = useColorModeValue('secondaryGray.300', 'navy.700');
	const brandButton = useColorModeValue('brand.500', 'brand.400');
	const [messagesObject, setMessagesObject] = useState([]);
	const [gotMessagesObject, setGotMessagesObject] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const { user } = useUser();
	const user_id = user.id;
	const full_name = user.fullName;
	const [recipient, setRecipient] = useState('assistant');
	// Ellipsis modals
	const [messages, setMessages] = useState([])

	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();

	useEffect(() => {
		const url =  `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&fields=messages`
		if (!gotMessagesObject) {
			setShowSpinner(true);
			fetch(url, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			}).then((data) => data.json())
				.then((resp) => {
					if (resp) {
						setMessagesObject(resp['messages'])
					} else {
						setMessagesObject([])
					}
					setGotMessagesObject(true)
					setShowSpinner(false);
				}).catch((err) => {
				setGotMessagesObject(true)
				setShowSpinner(false);
			});
		}
		console.log("messagesObject");
		console.log(messagesObject);
	});


	const sendMessage = (msg: string, to: string) => {
		setMessages([...messages, {'sender': full_name, 'content': msg}])

		const url =  `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_SEND_MESSAGE_EP}?user_id=${user_id}&recipient=${to}&message=${msg}&sender=${full_name}&recipient=${recipient}`
		setShowSpinner(true)
		fetch(url,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			}).then((data) => data.json())
			.then((resp) => {
				console.log("messages.117 printing resp");
				console.log(resp)
				if (resp && resp['data']) {
					setMessagesObject([resp['data']])
				} else {
					setMessagesObject([])
				}
				// setShowSpinner(false);
				setGotMessagesObject(false);
			}).catch((err) => {
			// setShowSpinner(false);
			setGotMessagesObject(false);
		});
	}


	// Chakra Color Mode
	const textHover = useColorModeValue(
		{ color: 'secondaryGray.900', bg: 'unset' },
		{ color: 'secondaryGray.500', bg: 'unset' }
	);
	const bgList = useColorModeValue('white', 'whiteAlpha.100');
	const bgShadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
	let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
	const { isOpen, onOpen, onClose } = useDisclosure();
	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gridTemplateColumns={{ base: '1.5fr 1.83fr', lg: '1fr 1.83fr' }}
			gap={{ base: '20px', xl: '20px' }}>
			<Card gridArea='1 / 1 / 2 / 2' p='0px' px='20px' pt='20px' pb='40px'>
				 {/*<Conversations onOpenMessages={onOpen} /> */}
				<Box height='720px'>
					<Box>
						<Flex mb='15px' align='center' justify='space-between'>
							<Text color={textColor} fontSize='xl' fontWeight='700'>
								Chats between your agent and customers show up here
							</Text>
						</Flex>
					</Box>
					<>
						{ messagesObject.map((m, index) => (
						<Box key={index} onClick={() => {setRecipient(m.recipient)}}>
							{m && m['data'] ?
								<ChatHeader
									name={m.recipient}
									// lastMessage={''}
									lastMessage={m['data'][m['data'].length - 1].content.substr(0, 75) + '...'}
									sum='-$15.50'
									avatar={''}
									hour=''
								/> : <></>
							}
						</Box>
						))}
					</>
				</Box>
			</Card>
			<Card display={{ base: 'none', md: 'flex' }} h='100%' gridArea='1 / 2 / 2 / 3' px='0px'>
				{messagesObject.map((m, index) => (
					m.recipient == recipient ?
						<Messages messages={m.data} status='active' recipient={recipient}
								  setShowSpinner={setShowSpinner} full_name={full_name}
								  setMessagesObject={setMessagesObject} showSpinner={showSpinner}
								  sendMessage={sendMessage}
						/>
						: <></>
				))
				}
			</Card>
			<Drawer isOpen={isOpen} placement='left' onClose={onClose}>
				<DrawerContent
					w='100%'
					maxW='calc(100vw - 32px)'
					ms={{
						sm: '16px'
					}}
					my={{
						sm: '16px'
					}}
					borderRadius='16px'
					bg={sidebarBackgroundColor}>
					<DrawerCloseButton
						boxShadow='unset !important'
						_hover={{ bg: 'transparent' }}
						left='0px !important'
						top='18px'
						color='secondaryGray.700'>
						<Icon as={MdChevronLeft} color={searchIconColor} w='28px' h='28px' />
					</DrawerCloseButton>
				</DrawerContent>
			</Drawer>
		</Grid>
	);
}
