/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/

=========================================================
* Equinox Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Equinox (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {Box, Flex, Grid, Icon, Text, useColorModeValue} from '@chakra-ui/react';
// Custom components
import Balance from 'views/admin/dashboards/default/components/Balance';
import DailyTraffic from 'views/admin/dashboards/default/components/DailyTraffic';
import MostVisitedTable from 'views/admin/dashboards/default/components/MostVisitedTable';
import { VSeparator } from 'components/separator/Separator';
import OverallRevenue from 'views/admin/dashboards/default/components/OverallRevenue';
import ProfitEstimation from 'views/admin/dashboards/default/components/ProfitEstimation';
import ProjectStatus from 'views/admin/dashboards/default/components/ProjectStatus';
import YourCard from 'views/admin/dashboards/default/components/YourCard';
import YourTransfers from 'views/admin/dashboards/default/components/YourTransfers';
import tableDataMostVisited from 'views/admin/dashboards/default/variables/tableDataMostVisited';
import AddDevice from "../smartHome/components/AddDevice";
import SampleForm from "../../main/profile/overview/components/SampleForm";
import React from "react";

export default function Default() {
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Flex gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
          <SampleForm/>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />

    </Flex>
  );
}
