// Chakra imports
import {
	Avatar,
	Box,
	Button,
	Flex,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import scrollToBottom, { Scrollbars } from 'react-custom-scrollbars-2';
import MessageBlock from 'components/chat/MessageBlock';
import React, {useEffect, useRef, useState} from 'react';
import { Spinner } from '@chakra-ui/react'
// Assets
import { messagesRenderThumb, messagesRenderTrack, messagesRenderView } from 'components/scrollbar/Scrollbar';
import { FaCircle } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { IoPaperPlane } from 'react-icons/io5';
import {
	MdTagFaces,
	MdOutlineCardTravel,
	MdOutlineLightbulb,
	MdOutlineMoreVert,
	MdOutlinePerson,
	MdOutlineSettings,
	MdOutlineImage,
	MdAttachFile,
	MdAdd
} from 'react-icons/md';
import avatar2 from 'assets/img/avatars/avatar2.png';
import {useUser} from "@clerk/clerk-react";

// @ts-ignore
function LeftMessage(msg) {
	return (
		<Flex overflow='hidden'>
			<Flex
				direction='column'
				w='100%'
				maxW={{ base: '90%', lg: 'calc(100% - 80px)' }}
				boxSizing='border-box'>
				<MessageBlock content={msg.msg.content} time={msg.msg.timestamp} side='left' />
			</Flex>
		</Flex>
	)
}

// @ts-ignore
function RightMessage(msg) {
	return (
		<Flex mb='50px' overflow='hidden' w='94%' ms='auto' justify='end'>
			<Flex
				direction='column'
				w='calc(90%)'
				maxW={{ base: '90%', lg: 'calc(100% - 80px)' }}
				boxSizing='border-box'
				alignItems='flex-end'>
				<MessageBlock
					content={msg.msg.content}
					time={msg.msg.timestamp}
				/>
			</Flex>
		</Flex>
	)
}

// @ts-ignore
function ShowMessage(msg) {
	if (msg.msg.sender === msg.full_name){
		return (<RightMessage msg={msg.msg}/>)
	}

	return (<LeftMessage msg = {msg.msg}/>)
}



export default function Messages(props: { sendMessage: any; messages: any; setShowSpinner: any; setMessagesObject: any; status: string; recipient: string; [x: string]: any; showSpinner: any;}) {
	const { sendMessage, messages, setShowSpinner, setMessagesObject, showSpinner, status, recipient,...rest } = props;
	const [messageInput, setMessageInput] = React.useState('');
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const inputColor = useColorModeValue('secondaryGray.700', 'secondaryGray.700');
	const inputText = useColorModeValue('gray.700', 'gray.100');
	const blockBg = useColorModeValue('secondaryGray.300', 'navy.700');
	const brandButton = useColorModeValue('brand.500', 'brand.400');
	const bgInput = useColorModeValue(
		'linear-gradient(1.02deg, #FFFFFF 49.52%, rgba(255, 255, 255, 0) 99.07%)',
		'linear-gradient(1.02deg, #111C44 49.52%, rgba(17, 28, 68, 0) 99.07%)'
	);

	const scrollbars = useRef<Scrollbars>(null);

	// Ellipsis modals
	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
	const { user } = useUser();
	const user_id = user.id;
	const full_name = user.fullName;

	// Chakra Color Mode
	const textHover = useColorModeValue(
		{ color: 'secondaryGray.900', bg: 'unset' },
		{ color: 'secondaryGray.500', bg: 'unset' }
	);
	const bgList = useColorModeValue('white', 'whiteAlpha.100');
	const brandColor = useColorModeValue('brand.500', 'white');
	const bgShadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
	const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
	return (
		<Box h='100%' {...rest}>
			<Flex px='34px' pb='25px' borderBottom='1px solid' borderColor={borderColor} align='center'>
				<Avatar
					h={{ base: '40px', '2xl': '50px' }}
					w={{ base: '40px', '2xl': '50px' }}
					src=''
					me='16px'
				/>
				<Box>
					<Text color={textColor} fontSize={{ base: 'md', md: 'xl' }} fontWeight='700'>
						{recipient}
					</Text>
					<Flex align='center'>
						<Icon
							w='6px'
							h='6px'
							me='8px'
							as={FaCircle}
							color={status === 'active' ? 'green.500' : status === 'away' ? 'orange.500' : 'red.500'}
						/>
						<Text fontSize={{ base: 'sm', md: 'md' }}>
							{status === 'Operational' ? 'Operational' : 'Operational'}{' '}
						</Text>
					</Flex>
				</Box>
				<Flex align='center' ms='auto' />
			</Flex>
			<Box h='calc(100% - 80px)' px={{ base: '10px', md: '20px' }} pt='45px' position='relative'>
				<div style={{ height: '100%' }}>
				<Scrollbars
					autoHide
					renderTrackVertical={messagesRenderTrack}
					renderThumbVertical={messagesRenderThumb}
					renderView={messagesRenderView}>
					<Scrollbars
						autoHide
						renderTrackVertical={messagesRenderTrack}
						renderThumbVertical={messagesRenderThumb}
						renderView={messagesRenderView}>
						{messages ? messages.map((msg: any, i: any) => (
								<>
								<ShowMessage key={i} msg={msg} full_name={full_name}/>
									<br/>
									<br/>
									<br/>
								</>
							)
						) : ''}
						{showSpinner ?
							<Spinner color='red.500'/> : <></>
						}
					</Scrollbars>
				</Scrollbars>
				</div>
				<Flex
					bg={bgInput}
					backdropFilter='blur(20px)'
					pt='10px'
					position='absolute'
					w={{ base: 'calc(100% - 20px)', md: 'calc(100% - 40px)' }}
					bottom='0px'>
					<InputGroup me='10px' w={{ base: '100%' }}>
						<Input
							variant='search'
							fontSize='md'
							pl={{ base: '40px !important', lg: '65px !important' }}
							pr={{
								base: '0px',
								lg: '145px !important'
							}}
							h={{ base: '50px', lg: '70px' }}
							bg={blockBg}
							color={inputText}
							value={messageInput}
							onChange={(e)=> setMessageInput(e.currentTarget.value)}
							onKeyPress={e=> {
								if (e.key === 'Enter') {
									// location.assign('?wd=' + messageInput)
									sendMessage(messageInput, recipient)
								}
							}}
							fontWeight='500'
							_placeholder={{ color: 'gray.400', fontSize: '16px' }}
							borderRadius={'50px'}
							placeholder={'Write your message...'}
						/>
					</InputGroup>
					<Button
						borderRadius='50%'
						ms={{ base: '14px', lg: 'auto' }}
						bg={brandButton}
						w={{ base: '50px', lg: '70px' }}
						h={{ base: '50px', lg: '70px' }}
						minW={{ base: '50px', lg: '70px' }}
						minH={{ base: '50px', lg: '70px' }}
						variant='no-hover'>
						<Icon
							as={IoPaperPlane}
							color='white'
							w={{ base: '18px', lg: '25px' }}
							h={{ base: '18px', lg: '25px' }}
						/>
					</Button>
				</Flex>
			</Box>
		</Box>
	);
}
