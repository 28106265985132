import {Icon} from '@chakra-ui/react';
import {MdAnalytics, MdDashboard, MdHome, MdLock,} from 'react-icons/md';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';

import Default from 'views/admin/dashboards/dragNDrop';

// // NFT Imports
// Main Imports
// Others
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';


const routes = [
  {
    name: 'Main',
    layout: '/admin',
    path: '/dashboards/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit"/>,
    collapse: false,
    component: <DashboardsDefault/>,
  },
  // {
  //   name: 'Demo Form',
  //   layout: '/admin',
  //   path: '/dashboards/demo',
  //   collapse: false,
  //   icon: <Icon as={MdAnalytics} width="20px" height="20px" color="inherit" />,
  //   // @ts-ignore
  //   items: [],
  //   component: <DashboardsDefault2 />,
  // },
  {
    name: 'Demo Form',
    layout: '/admin',
    path: '/dashboards/demo',
    collapse: false,
    icon: <Icon as={MdAnalytics} width="20px" height="20px" color="inherit"/>,
    // @ts-ignore
    items: [],
    component: <Default/>,
  },
  {
    name: 'Messages',
    layout: '/admin',
    path: '/main/others/messages',
    collapse: false,
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit"/>,
    // @ts-ignore
    items: [],
    component: <Messages/>,
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in/default',
            component: <SignInDefault />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default',
            component: <SignUpDefault />,
          },
        ],
      },
    ],
  },
];

export default routes;
