// Chakra imports
import {
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	Spinner,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
// Assets
import Project1 from 'assets/img/users/Project1.png';
// Custom components
import Card from 'components/card/Card';

import Numbers from 'views/admin/main/profile/overview/components/Numbers';
import {MdCall} from 'react-icons/md';
import React, {useEffect, useState} from "react";
import {useUser} from "@clerk/clerk-react";

// @ts-ignore
export default function ConnectedNumbers() {
	// Chakra Color Mode
	const { user } = useUser();
	const user_id = user.id;

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const [phoneNumberInput, setPhoneNumberInput] = useState('+1');
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [gotNumbers, setGotNumbers] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const handleChange = (e: any) => {
		const regex = /^[+0-9\b]+$/;

		if (e.target.value.substr(0,2) == '+1' && e.target.value.length >= 2
			&& e.target.value.length <= 12 && regex.test(e.target.value)) {
			setPhoneNumberInput(e.target.value);
		}
	};

	useEffect(() => {
		if (!gotNumbers) {
			const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&fields=phone_number`
			console.log(url);
			fetch(url, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			}).then((data) => data.json())
				.then((resp) => {
					console.log("go phone numbers object " + JSON.stringify(resp))
					setPhoneNumbers(resp['phone_number'])
					setGotNumbers(true);
					setShowSpinner(false);
				}).catch((error) => {
					setGotNumbers(true);
					setShowSpinner(false);
			})
		}
		console.log("phone numbers look like: ");
		console.log(phoneNumbers);
	});

	const addAccountNumber = (number: any) => {
		if (number.length == 12) {
			const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&phone_number=${phoneNumberInput}&fields=phone_number`
			console.log(url);
			fetch(url, {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					phone_number: number,
					user_id: user_id,
					fields: 'phone_number'
				})
			}).then((data) => data.json())
				.then((resp) => {
					setGotNumbers(false);
				});
		}

	}
	return (
		<Card mb={{ base: '0px', '2xl': '20px' }}>
			<FormControl>
				<FormLabel>Phone Number</FormLabel>
				<Input
					value={phoneNumberInput}
					type="phone"
					onChange={handleChange}
				/>
				<FormHelperText> </FormHelperText>
			</FormControl>
			<Button rightIcon={<MdCall />} colorScheme='blue' variant='outline' onClick={() => addAccountNumber(phoneNumberInput)}>
				Add Number
			</Button>

			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Connected Number
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				These are a list of numbers connected to the app. Our app will forward you notifications at this number.
			</Text>
			{ phoneNumbers && phoneNumbers.map((phone_number: string, i: number) => (
					// @ts-ignore: Object is possibly 'null'.
					<Numbers mb='20px' image={Project1} ranking={(i+1).toString()} link='#' setGotNumbers={setGotNumbers} phone_number={phone_number} setShowSpinner={setShowSpinner}/>
				))
			}
			{showSpinner ? <Spinner color='red.500'/> : <></>}
			{/*<Project mb='20px' image={Project1} ranking='1' link='#' title='Technology behind the Blockchain' />*/}
			{/*<Project mb='20px' image={Project2} ranking='2' link='#' title='Greatest way to a good Economy' />*/}
			{/*<Project image={Project3} ranking='3' link='#' title='Most essential tips for Burnout' />*/}
		</Card>
	);
}
