// Chakra imports
// Assets
// Custom components
import React, {useState} from "react";
import {useUser} from "@clerk/clerk-react";

// import SortableTree from 'react-sortable-tree';
const SortableTree = require('react-sortable-tree');
const FileExplorerTheme = require('react-sortable-tree-theme-full-node-drag');
// import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

// @ts-ignore
export default function ConnectedNumbers() {
	// Chakra Color Mode
	const {user} = useUser();
	const user_id = user.id;

	const [treeData, setTreeData] = useState([{title: 'src/', children: [{title: 'index.js'}]}]);


	return (
		<div style={{height: 400}}>
			<SortableTree
				treeData={treeData}
				onChange={(newTreeData: any) => setTreeData(newTreeData)}
				theme={FileExplorerTheme}
			/>
		</div>
	);
}

