// Chakra imports
import { Box, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import React, {useEffect, useState} from "react";

// Custom components
import Card from 'components/card/Card';
import Dropzone from 'views/admin/main/ecommerce/settingsProduct/components/Dropzone';
import { FileUploader } from "react-drag-drop-files";

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';
import ConnectedDocuments from "../../../profile/overview/components/ConnectedDocuments";
import {useUser} from "@clerk/clerk-react";
import { Spinner } from '@chakra-ui/react'

const fileTypes = ["PDF", "TXT", "CSV"];

export default function DropzoneCard(props: { [x: string]: any }) {

	const { ...rest } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brand = useColorModeValue('brand.500', 'brand.400');

	const [connectedDocuments, setConnectedDocuments] = useState([]);
	const [gotDocuments, setGotDocuments] = useState(false); // # CHANGE THIS
	const [showSpinner, setShowSpinner] = useState(false);
	const { user } = useUser();
	const user_id = user.id;

	useEffect(() => {
		if (!gotDocuments) {
			const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&fields=file_details`
			fetch(url, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			}).then((data) => data.json())
				.then((resp) => {
					console.log("called " + url);
					console.log("the response is ")
					console.log(resp);
					setConnectedDocuments(resp['file_details']);
				});
			setGotDocuments(true);
			setShowSpinner(false);
		}
		console.log("Connected Documents");
		console.log(connectedDocuments);
	})


	// @ts-ignore
	const handleChange = (file: any) => {
		const url = `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_FILE_UPLOAD_EP}?user_id=${user_id}&file_name=${file.name}&file_type=${file.type}`
		setShowSpinner(true);
		fetch(url, {
			method: 'POST',
			body: file,
			mode: 'no-cors',
			headers: {
				'Content-Type': 'multipart/form-data',
				'Accept': '*/*',
				'Connection': 'keep-alive',
				'Accept-Encoding': 'gzip, deflate, br',
				'Access-Control-Allow-Origin':'*',
				'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'}

		}).then((data) => data.json())
			.then((resp) => {
				const data = resp['raw']
				setConnectedDocuments(data);
				setGotDocuments(false);
			}).catch((err) => {
				console.log("erred out");
				console.log(err);
				setGotDocuments(false);
		})
	};

	return (
		<Card p='30px' {...rest}>
			<Text color={textColor} fontSize='xl' fontWeight='700' mb='30px'>
				Help your agent understand more about your business
			</Text>

			<Dropzone
				content={
					<Box maxW='100%'>
						<Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
						<Text
							fontSize='sm'
							fontWeight='500'
							color='secondaryGray.500'
							white-space='pre-wrap !important'>
							Drop menus, client notes, business hours, and any other data you'd like our agent to know.
							<br/>This helps our agent understand context on your business to answer queries.
							<br/>PDF files are accepted.
						</Text>
						<FileUploader handleChange={handleChange} name="file" types={fileTypes} />
					</Box>
				}
			/>

			<ConnectedDocuments connected_documents={connectedDocuments} setGotDocuments={setGotDocuments} setShowSpinner={setShowSpinner}/>
			{showSpinner ? <Spinner /> : <></>}
		</Card>
	);
}
