// Chakra imports
import {Button, Input, Spinner, Text, useColorModeValue} from '@chakra-ui/react';
// Assets
import Project1 from 'assets/img/users/Project1.png';
import Project2 from 'assets/img/users/Project2.png';
import Project3 from 'assets/img/users/Project3.png';
// Custom components
import Card from 'components/card/Card';

import Numbers from 'views/admin/main/profile/overview/components/Numbers';
import {
	FormControl,
	FormLabel,
	FormHelperText,
} from '@chakra-ui/react'
import { MdCall } from 'react-icons/md';
import React, {useEffect, useState} from "react";
import {useUser} from "@clerk/clerk-react";

// @ts-ignore
export default function LaunchApp() {

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const { user } = useUser();
	const user_id = user.id;
	const href_url =  `https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=channels:history,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,files:read,groups:history,groups:read,groups:write,im:history,im:read,im:write,metadata.message:read,mpim:history,mpim:read,mpim:write,users:read,channels:write.invites,groups:write.invites,mpim:write.invites,groups:write.topic,im:write.invites,im:write.topic,mpim:write.topic&user_scope=channels:history,channels:read,channels:write,chat:write,files:read,groups:read,groups:write,im:history,im:read,im:write,links:write,mpim:history,mpim:read,mpim:write,users:read,channels:write.invites,channels:write.topic,groups:write.invites,mpim:write.invites,groups:write.topic,im:write.topic,im:write.invites,mpim:write.topic`

	return (
		<Card mb={{ base: '0px', '2xl': '20px' }}>
			<a href={href_url}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Launch App
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				Click this button to add the app to slack and launch.
			</Text>
		</Card>
	);
}
