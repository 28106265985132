// Chakra imports
import {Button, Input, Spinner, Text, useColorModeValue} from '@chakra-ui/react';
// Assets
import Project1 from 'assets/img/users/Project1.png';
// Custom components
import Card from 'components/card/Card';

import Numbers from 'views/admin/main/profile/overview/components/Numbers';
import {
	FormControl,
	FormLabel,
	FormHelperText,
} from '@chakra-ui/react'
import {MdOutlineWeb} from 'react-icons/md';
import React, {useEffect, useState} from "react";
import {useUser} from "@clerk/clerk-react";
import Websites from './Websites';
import {IconType} from "react-icons/lib";

// @ts-ignore
export default function ConnectedWebsites() {
	// Chakra Color Mode
	const { user } = useUser();
	const user_id = user.id;

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const [websiteInput, setWebsiteInput] = useState("");
	const [websites, setWebsites] = useState([]);
	const [gotWebsites, setGotWebsites] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);

	const handleChange = (e: any) => {
		setWebsiteInput(e.target.value);
	};

	useEffect(() => {
		if (!gotWebsites) {
			// process.env.NEXT_PUBLIC_BACKEND
			const url = process.env.REACT_APP_API_ENDPOINT + `${process.env.REACT_APP_USER_DATA_EP}?user_id=${user_id}&fields=websites`
			fetch(url, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			}).then((data) => data.json())
				.then((resp) => {
					console.log("called: " + url);
					console.log("and got response object:");
					console.log(JSON.stringify(resp));

					setWebsites(resp['websites']);
					setGotWebsites(true);
					setShowSpinner(false);
				}).catch((error) => {
					setGotWebsites(true);
					setShowSpinner(false);
			})
		}
		console.log('websites look like')
		console.log(websites)
	});

	const addWebsite = (website: any) => {
		const url =  `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_USER_DATA_EP}?website=${website}&user_id=${user_id}fields=website`
		setShowSpinner(true);
		fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				website: website,
				user_id: user_id,
			})
		}).then((data) => data.json())
			.then((resp) => {
				setGotWebsites(false);
			}).catch((err) => {
				setGotWebsites(false);

		});
	}

	return (
		<Card mb={{ base: '0px', '2xl': '20px' }}>
			<FormControl>
				<FormLabel>Websites</FormLabel>
				<Input
					value={websiteInput}
					type='website'
					onChange={handleChange}
				/>
				<FormHelperText> </FormHelperText>
			</FormControl>
			<Button rightIcon={<MdOutlineWeb />} colorScheme='blue' variant='outline' onClick={() => addWebsite(websiteInput)}>
				Add Websites
			</Button>

			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Connected Websites
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				These are a list of websites connected to the app. The agent knows all the context uploaded to these sites. We will scrape these websites daily for updates.
				Update sites containing your menu, information on your business and more to make it easily accessible by our agent.
			</Text>
			{
				websites ? websites.map((website: string, i: number) => (
					// @ts-ignore: Object is possibly 'null'.
					<Websites mb='20px' image={Project1} ranking={(i+1).toString()} link='#' website={website} setShowSpinner={setShowSpinner} setGotWebsites={setGotWebsites}/>
				)) : <></>
			}
			{showSpinner ? <Spinner color='red.500'/> : <></> }
			{/*<Project mb='20px' image={Project1} ranking='1' link='#' title='Technology behind the Blockchain' />*/}
			{/*<Project mb='20px' image={Project2} ranking='2' link='#' title='Greatest way to a good Economy' />*/}
			{/*<Project image={Project3} ranking='3' link='#' title='Most essential tips for Burnout' />*/}
		</Card>
	);
}
